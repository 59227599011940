<template>
  <index-page-title>
    <template #title>
      <div class="flex mt-6">
        <div
          class="flex items-center justify-center h-9 bg-v-ui-root-add-another-2 px-4 rounded-full rounded-bl-none xl:h-12"
        >
          <span
            class="text-v-ui-text-monochrome text-base font-medium xl:text-xl"
          >
            {{ $t('domains.domains.pages.subscription.issue-theme') }}
          </span>
        </div>
      </div>

      <span class="block text-v-ui-root-monochrome-0 mt-6">
        {{ $t('domains.domains.pages.subscription.title-1') }}? <br />
        {{ $t('domains.domains.pages.subscription.title-2') }}
        <br />
        {{ $t('domains.domains.pages.subscription.title-3') }}
      </span>
    </template>

    <template #description>
      <span class="text-v-ui-root-monochrome-5">
        {{ $t('domains.domains.pages.subscription.description-1') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.subscription.description-2') }} <br />
        {{ $t('domains.domains.pages.subscription.description-3') }}
      </span>
    </template>
  </index-page-title>
</template>

<script>
import IndexPageTitle from '../index-page-title/IndexPageTitle';

export default {
  name: 'SubscriptionTitle',
  components: { IndexPageTitle }
};
</script>

<style scoped></style>
